/**
 * Shared utility functions for CARE Tool
 */

export const isPlcModeEnabled = (survey) => survey['plc_mode?'];
export const isHqimAnalysisIncluded = (survey) => survey['includes_hqim_analysis?'];
export const isRacialDiversityAnalysisIncluded = (survey) => survey['includes_racial_diversity_analysis?'];
export const isClassGroupingAnalysisEnabled = (survey) => survey['class_grouping_analysis?'];
export const isScriptingNotesEnabled = (survey) => survey['enable_scripting_notes?'];
export const isLangOfInstructionIncluded = (survey) => survey['includes_language_of_instruction?'];
export const isShareObservationOnCompleteEnabled = (survey) => survey['enable_send_email_dialog_on_observation_complete'];
export const isReportDisplaySummaryOnly = (survey) => survey['report_display_summary_only?'];
export const isShowAdmNumbers = (survey) => survey['show_adm_numbers?'];

// Per #3467, hiding student engagement data (chart and filters)
export const hideClassGroupingData = (survey) => {
  return isPlcModeEnabled(survey) || !isClassGroupingAnalysisEnabled(survey);
};

export const isPointScaleQuestion = (node) => {
  return node.survey_config.question_type.includes('_point_scale');
};

export const getNumPoints = (node) => {
  return isPointScaleQuestion(node) ? parseInt(node.survey_config.question_type.replace('_point_scale', '')) : null;
};
