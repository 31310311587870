import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faRotate } from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassMinus } from '@fortawesome/pro-regular-svg-icons';
import Meta from './meta';
import AdmTreeResponses from './adm_tree_responses';
import Footer from '../../shared/footer';
import { updateObservation } from '../../../apis/care/observations/api';
import { createApi as createResponseApi } from '../../../apis/care/responses/api';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Editor from '../../shared/rte/editor';
import ReadOnlyRichText from '../../shared/rte/readonly_rich_text';
import { getFeatureFlagValue, updateFeatureFlagAfterClientInitialization } from '../../../helpers/get_feature_flag_value';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SnackMessage from '../../shared/snack_message';

import GoalsTable from '../goals/goals_table';
import { fetchObservableGoals } from '../../../apis/care/goals/api';
import PublishModal from './publish_modal';

import useGoalColumns from '../goals/use_goal_columns';

import {
  isHqimAnalysisIncluded,
  isRacialDiversityAnalysisIncluded,
  isClassGroupingAnalysisEnabled,
  isPlcModeEnabled,
  isScriptingNotesEnabled,
  isLangOfInstructionIncluded,
  isShareObservationOnCompleteEnabled,
} from '../shared/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const ObservationDialog = ({
  survey,
  observation: initialObservation,
  onClose,
  baseUrl,
  observableGoalsBaseUrl,
  actionStepsBaseUrl,
  readOnly,
  leads,
  recipients,
  can_add_internal_teachers = false,
}) => {
  const dialogRef = useRef(null);
  const dialogContentRef = useRef(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleOpenSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  // Form State
  const [observation, setObservation] = React.useState(initialObservation || {});
  const [observables, setObservables] = React.useState(
    (survey?.observables || []).filter((observable) => observable.available).sort((a, b) => a.name.localeCompare(b.name))
  );
  const [schoolNames, setSchoolNames] = React.useState((survey?.school_names || []).sort((a, b) => a.name.localeCompare(b.name)));
  const [contentAreaId, setContentAreaId] = React.useState(observation.content_area ? observation.content_area.id : null);
  const [instructionLanguageId, setInstructionLanguageId] = React.useState(
    observation.instruction_language ? observation.instruction_language.id : null
  );

  // if this is a new observation, we need to grab the survey id from the survey itself
  // if we are accessing this dialog from an index view of observations, we will not have the full
  // survey data so just access the survey id directly from the observation itself.
  const observationsBaseUrl = `${baseUrl}/${observation.survey_id || survey.id}/observations`;

  // Observable Goals State
  const [currentObservable, setCurrentObservable] = React.useState(null);
  const [observableGoals, setObservableGoals] = React.useState([]);

  // Status and Errors
  const [isSaving, setIsSaving] = React.useState(false);
  const [saveStatus, setSaveStatus] = React.useState('');
  const [errors, setErrors] = useState({});

  // Feature Flags
  const [showObservableAnalysis, setShowObservableAnalysis] = useState(getFeatureFlagValue('show-teacher-analysis'));
  const [enableRichTextEditors, setEnableRichTextEditors] = useState(getFeatureFlagValue('enable-rich-text-editors'));
  useEffect(updateFeatureFlagAfterClientInitialization('show-teacher-analysis', setShowObservableAnalysis), []);
  useEffect(updateFeatureFlagAfterClientInitialization('enable-rich-text-editors', setEnableRichTextEditors), []);

  // Admin Flags
  const showHqim = isHqimAnalysisIncluded(survey);
  const showPredominantlyPoc = isRacialDiversityAnalysisIncluded(survey);
  const showClassGrouping = isClassGroupingAnalysisEnabled(survey);
  const isPlcMode = isPlcModeEnabled(survey);
  const enableScriptingNotes = isScriptingNotesEnabled(survey);
  const includesLanguageOfInstruction = isLangOfInstructionIncluded(survey);
  const enableSendEmailDlgOnComplete = isShareObservationOnCompleteEnabled(survey);

  // Publish Modal State
  const [showPublishModal, setShowPublishModal] = React.useState(false);
  const [closeOnAnyPublishAction, setCloseOnAnyPublishAction] = React.useState(false);

  const addItemIfNotExists = (list, item, key = 'id') => {
    const existingItem = list.find((existing) => existing[key] === item[key]);
    if (!existingItem) {
      return [...list, item];
    }
    return list;
  };

  const handleStatusUpdate = (startTime, status) => {
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;

    if (elapsedTime < 500) {
      setTimeout(() => {
        setIsSaving(false);
        setSaveStatus(status);
      }, 500 - elapsedTime);
    } else {
      setIsSaving(false);
      setSaveStatus(status);
    }
  };

  const handleSaveMeta = async (payload) => {
    const startTime = Date.now();
    setIsSaving(true);
    setSaveStatus('Saving...');

    try {
      let response = await updateObservation(observation.id, payload, observationsBaseUrl);
      setObservation(response);

      if (response.observable) {
        setObservables((prevObservables) => {
          const newObservables = addItemIfNotExists(prevObservables, response.observable);
          return newObservables.sort((a, b) => a.name.localeCompare(b.name));
        });
      }
      if (response.school_name) {
        setSchoolNames((prevSchoolNames) => {
          const newSchoolNames = addItemIfNotExists(prevSchoolNames, response.school_name);
          return newSchoolNames.sort((a, b) => a.name.localeCompare(b.name));
        });
      }

      if (payload.observation_date && errors.observation_date) {
        delete errors.observation_date;
      }
      if (payload.school_name && errors.school_name) {
        delete errors.school_name;
      }
      if (payload.grade_level && errors.grade_level) {
        delete errors.grade_level;
      }
      if (payload.content_area && errors.content_area) {
        delete errors.content_area;
      }
      if (payload.number_of_students && errors.number_of_students) {
        delete errors.number_of_students;
      }
      if (payload.number_of_engaged_students && errors.number_of_engaged_students) {
        delete errors.number_of_engaged_students;
      }
      if (payload.hqim_in_use && errors.hqim_in_use) {
        delete errors.hqim_in_use;
      }
      if (payload?.hqim_type_ids?.length > 0 && errors.hqim_type) {
        delete errors.hqim_type;
      }
      if (payload.hqim_in_use === false && errors.hqim_type) {
        delete errors.hqim_type;
      }
    } catch (error) {
      setErrors(errors);
      setSaveStatus('Error saving');
    } finally {
      handleStatusUpdate(startTime, 'Saved');
    }
  };

  const isCompleted = () => observation?.status === 'completed';

  const handleComplete = async () => {
    try {
      const payload = { ...observation, status: 'completed' };
      let response = await updateObservation(observation.id, payload, observationsBaseUrl);
      setObservation(response);

      if (showObservableAnalysis && enableSendEmailDlgOnComplete) {
        setShowPublishModal(true);
        setCloseOnAnyPublishAction(true);
      } else {
        handleOnClose(response);
      }
    } catch (errors) {
      setErrors(errors);
      setSaveStatus('Error saving');

      if (dialogContentRef.current) {
        dialogContentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleCreateResponse = async (admNode, value, notes) => {
    const startTime = Date.now();
    setIsSaving(true);
    setSaveStatus('Saving...');

    try {
      const responseApi = createResponseApi(`/care/observations/${observation.id}`);
      const newResponse = await responseApi.create(admNode, value, notes);

      // Update the observation's responses
      if (newResponse) {
        setObservation((prevObservation) => {
          const existingIndex = prevObservation.responses.findIndex((r) => r.id === newResponse.id);

          if (existingIndex !== -1) {
            const updatedResponses = [...prevObservation.responses];
            updatedResponses[existingIndex] = newResponse;
            return { ...prevObservation, responses: updatedResponses };
          } else {
            return {
              ...prevObservation,
              responses: [...prevObservation.responses, newResponse],
            };
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleStatusUpdate(startTime, 'Saved');
    }
  };

  const handleOnClose = (newObservation) => {
    onClose(newObservation);
  };

  const handleOnClosePublishModal = () => {
    setShowPublishModal(false);
  };

  const handleSendComplete = () => {
    setShowPublishModal(false);
    handleOpenSnackbar('Email with observation PDF sent!');

    if (closeOnAnyPublishAction) {
      setTimeout(() => {
        onClose(observation);
      }, 1000);
    }
  };

  const completeButton = () => {
    if (readOnly) {
      return <div />;
    } else if (isCompleted()) {
      return (
        <Button variant="outlined" endIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: '0.9em' }} />} style={{ pointerEvents: 'none' }}>
          Completed
        </Button>
      );
    } else {
      return (
        <Button variant="contained" data-cy="button-complete-observation" onClick={handleComplete}>
          Complete
        </Button>
      );
    }
  };

  const renderNotes = () => {
    if (readOnly) {
      return observation.notes ? (
        <Stack direction="column" sx={{ my: 4 }}>
          <h1 className="headline-color">Notes</h1>
          <ReadOnlyRichText initialContent={observation.notes} />
        </Stack>
      ) : null;
    } else {
      return (
        <Accordion
          elevation={0}
          sx={{
            my: 4,
            borderRadius: '10px',
            borderTop: 0,
            border: '1px solid #ccc',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h1 className="headline-color notes-title">Notes</h1>
          </AccordionSummary>
          <AccordionDetails>
            <Editor
              initialContent={observation?.notes}
              placeholder={' Notes...'}
              containerRef={dialogRef}
              onContentChange={(notes) => {
                handleSaveMeta({ ...observation, notes: notes });
              }}
            />
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  const onObservableChange = async (observable) => {
    if (observable && observable.id) {
      setCurrentObservable(observable);
      try {
        const fetchedGoals = await fetchObservableGoals(observable.id, observableGoalsBaseUrl);
        setObservableGoals(fetchedGoals.length > 0 ? fetchedGoals : []);
      } catch (error) {
        console.error(error);
        setObservableGoals([]);
      }
    } else {
      setCurrentObservable(null);
      setObservableGoals([]);
    }
  };

  useEffect(() => {
    if (observation?.observable) {
      onObservableChange(observation.observable);
    }
  }, [observation]);

  const columnConfig = useGoalColumns({
    configType: 'singleObservable',
    leads: leads,
    showStatus: false,
    isEditable: false,
    showDescriptors: survey.goal_descriptors_enabled,
  });

  const [selectedZoomOut, setSelectedZoomOut] = React.useState(false);
  const zoomOutStyle = {
    transform: 'scale(0.75)',
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <span style={{ display: 'none' }} id="observationId">
        {observation.id}
      </span>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={true}
        onClose={() => handleOnClose(observation)}
        TransitionComponent={Transition}
        className={readOnly ? 'read-only' : ''}
        ref={dialogRef}
      >
        <div ref={dialogContentRef} style={{ height: '100%', overflowY: 'auto' }}>
          <AppBar
            position="sticky"
            style={{
              backgroundColor: 'white',
              borderBottom: '1px solid #ccc',
            }}
          >
            <Toolbar>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Typography variant="h6" color="textPrimary">
                  {survey.org_name}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 4 }}>
                  {isSaving && <FontAwesomeIcon icon={faRotate} className="fa-spin" style={{ color: green[500], fontSize: '16px' }} />}
                  {saveStatus === 'Saved' && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500], fontSize: '18px' }} />
                      <Typography variant="body2" color="primary" sx={{ ml: 1 }}>
                        Saved
                      </Typography>
                    </Box>
                  )}
                  {saveStatus === 'Saving...' && (
                    <Typography variant="body2" color="primary">
                      Saving...
                    </Typography>
                  )}
                </Stack>
              </Box>
              <Stack direction="row" spacing={3} alignItems="center">
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {completeButton()}
                    {showObservableAnalysis && (
                      <Button variant="contained" color="secondary" onClick={(e) => setShowPublishModal(true)}>
                        Share via Email
                      </Button>
                    )}
                  </Stack>
                </Box>
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ToggleButton value="check" selected={selectedZoomOut} onChange={() => setSelectedZoomOut(!selectedZoomOut)}>
                      <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                    </ToggleButton>
                    <IconButton aria-label="Close" data-cy="button-close-observation" onClick={() => handleOnClose(observation)}>
                      <FontAwesomeIcon icon={faXmark} color="#585858" />
                    </IconButton>
                  </Stack>
                </Box>
              </Stack>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              my: 4,
              mx: 'auto',
              width: '100%',
              maxWidth: '1024px',
              textAlign: 'left',
              ...(selectedZoomOut && zoomOutStyle),
            }}
          >
            <Meta
              errors={errors}
              survey={survey}
              observation={observation}
              notes={observation.notes}
              handleSave={handleSaveMeta}
              observables={observables}
              schoolNames={schoolNames}
              contentAreas={survey?.content_areas || []}
              instructionLanguages={survey?.instruction_languages || []}
              hqimTypes={survey?.hqim_types || []}
              setContentAreaId={(id) => setContentAreaId(id)}
              setInstructionLanguageId={(id) => setInstructionLanguageId(id)}
              readOnly={readOnly}
              onObservableChange={onObservableChange}
              showObservableAnalysis={showObservableAnalysis}
              showHqim={showHqim}
              showPredominantlyPoc={showPredominantlyPoc}
              showClassGrouping={showClassGrouping}
              isPlcMode={isPlcMode}
              includesLanguageOfInstruction={includesLanguageOfInstruction}
              can_add_internal_teachers={can_add_internal_teachers}
            />

            {currentObservable !== null && observableGoals?.length > 0 && (
              <GoalsTable
                title={`${currentObservable.name} Goals in Progress`}
                goals={observableGoals}
                leads={leads}
                enableActionSteps={true}
                canAddActionStep={true}
                enableTableActions={false}
                hideDataAnalysisColumn={true}
                actionStepsUrl={actionStepsBaseUrl}
                columnConfig={columnConfig}
                tableName="observationObservableGoals"
              />
            )}

            {enableScriptingNotes && renderNotes()}

            <AdmTreeResponses
              admTree={survey.adm_tree}
              observation={observation}
              createResponse={handleCreateResponse}
              contentAreaId={contentAreaId}
              readOnly={readOnly}
              showAdmNumbers={survey['show_adm_numbers?']}
              dialogRef={dialogRef}
              enableRichTextEditors={enableRichTextEditors}
            />
          </Box>
          <Footer />
        </div>
      </Dialog>
      <PublishModal
        open={showPublishModal}
        observation={observation}
        close={handleOnClosePublishModal}
        recipients={recipients}
        onSendComplete={handleSendComplete}
      />
      <SnackMessage isOpen={snackbarOpen} message={snackbarMessage} handleClose={handleCloseSnackbar} severity={snackbarSeverity} />
    </LocalizationProvider>
  );
};

const surveyShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  org_name: PropTypes.string.isRequired,
  observables: PropTypes.array.isRequired,
  school_names: PropTypes.array.isRequired,
  content_areas: PropTypes.array.isRequired,
  hqim_types: PropTypes.array.isRequired,
  adm_tree: PropTypes.array,
});

ObservationDialog.propTypes = {
  survey: surveyShape.isRequired,
  observation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  baseUrl: PropTypes.string,
  readOnly: PropTypes.bool,
  leads: PropTypes.array,
};

export default ObservationDialog;
