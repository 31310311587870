import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, Button } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { sendEmails } from '../../../apis/care/observations/api';
import Typography from '@mui/material/Typography';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => (option.full_name ? option.full_name.toLowerCase() : ''),
});

const PublishModal = ({ observation, open, close, recipients, onSendComplete }) => {
  const [updatedRecipients, setUpdatedRecipients] = useState(recipients);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [message, setMessage] = useState('');

  let isCompleted = observation?.status === 'completed';

  useEffect(() => {
    isCompleted = observation?.status === 'completed';
  }, [observation, recipients]);

  useEffect(() => {
    const observable = observation?.observable;
    if (observable?.id && observable?.name && observable?.email) {
      const observableRecipient = {
        id: observable.id,
        full_name: observable.name,
        email: observable.email,
      };

      const newRecipients = [observableRecipient, ...recipients.filter((recipient) => recipient.email !== observableRecipient.email)];
      setUpdatedRecipients(newRecipients);
      setSelectedRecipients([observableRecipient]);
    }
  }, [open, observation, recipients]);

  const addCoach = (e, value, reason) => {
    if (reason === 'selectOption' && value.filter((option) => option.id === 'all').length > 0) {
      setSelectedRecipients(updatedRecipients);
    } else {
      setSelectedRecipients(value);
    }
  };

  const handleClose = () => {
    close();
    setSelectedRecipients([]);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    const coachIds = selectedRecipients.map((coach) => coach.id);
    try {
      await sendEmails(observation.id, coachIds, message);
      onSendComplete();
      setSelectedRecipients([]);
    } catch (error) {
      console.error(error);
      handleClose();
    }
  };

  const description = () => {
    if (isCompleted) {
      return 'The observation has been completed. You can send a PDF version of this observation via email.';
    }
    return 'The observation is currently incomplete. You can still send a PDF version of this observation via email if needed.';
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Share via Email
        <Typography variant="body1" style={{ marginTop: '5px' }}>
          {description()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={[{ id: 'all', full_name: 'Select all..' }, ...updatedRecipients]}
          filterOptions={filterOptions}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Recipients" required />}
          getOptionLabel={(option) => option.full_name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={addCoach}
          value={selectedRecipients}
          renderOption={(props, item) => (
            <li {...props} key={item.id} value={item.id} id={item.id}>
              {item.full_name}
            </li>
          )}
          multiple
          size="small"
          sx={{ py: 1 }}
        />
        <FormControl fullWidth size="small">
          <TextField
            label="Custom Message"
            multiline
            rows={3}
            variant="outlined"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            required
          />
        </FormControl>
        <DialogActions sx={{ px: 0, pb: 0 }}>
          <Button onClick={handleClose}>Not Now</Button>
          <Button onClick={handleSend} variant="contained">
            Send
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

PublishModal.propTypes = {
  observation: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
  recipients: PropTypes.array,
  onSendComplete: PropTypes.func,
};

export default PublishModal;
