import React from 'react';
import PropTypes from 'prop-types';
import ObservationDialog from './observation_dialog';
import MetaTable from './exportable/meta_table';
import PdfAdmTreeResponses from './exportable/pdf_adm_tree_responses';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { themeAttuned } from '../../../constants/theme';
import { ConfirmDialogProvider } from '../../modals/confirm_dialog';

const noop = () => {};

const ObservationDialogAdapter = ({ isPdf = false, ...props }) => {
  if (isPdf) {
    const { survey, observation } = props;
    const metaProps = {
      readOnly: true,
      errors: {},
      hqimTypes: [],
      observables: [],
      schoolNames: [],
      contentAreas: [],
      instructionLanguages: [],
      handleSave: noop,
      onObservableChange: noop,
      setContentAreaId: noop,
      setInstructionLanguageId: noop,
      ...props,
    };

    const admTreeProps = {
      isPdf: true,
      readOnly: true,
      admTree: survey.adm_tree,
      observation: observation,
      createResponse: noop,
      contentAreaId: observation?.content_area ? observation.content_area.id : null,
      showAdmNumbers: survey['show_adm_numbers?'],
      enableRichTextEditors: true,
    };

    return (
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MetaTable {...metaProps} />
            <PdfAdmTreeResponses {...admTreeProps} />
          </LocalizationProvider>
        </ConfirmDialogProvider>
      </ThemeProvider>
    );
  }

  return <ObservationDialog {...props} />;
};

export default ObservationDialogAdapter;

ObservationDialogAdapter.propTypes = {
  survey: PropTypes.object.isRequired,
  observation: PropTypes.object.isRequired,
};
